@font-face {
    font-family: 'Roboto-Black';
    src: url('https://uhoo.io/wp-content/themes/blanka-wp/fonts/Roboto-Black');
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('https://uhoo.io/wp-content/themes/blanka-wp/fonts//Roboto-Bold');
}
@font-face {
    font-family: 'Roboto-Light';
    src: url('https://uhoo.io/wp-content/themes/blanka-wp/fonts/Roboto-Light');
}
@font-face {
    font-family: 'Roboto-Regular';
    src: url('https://uhoo.io/wp-content/themes/blanka-wp/fonts/Roboto-Regular');
}
@font-face {
    font-family: 'Roboto-Medium';
    src: url('https://uhoo.io/wp-content/themes/blanka-wp/fonts/Roboto-Medium');
}

.navegation {
    padding: 0px 6%;
    width: 100%;
    display: flex;
    height: 80px;
    background-color: #00325b;
}
.logo-somos-vanti {
    margin: 8% auto;
    width: 34%;
    max-width: 170px;
}
.logo-navigation {
    width: 130px;
    margin: auto 0px;
}
.container-message {
    width: 100%;
    margin: auto;
    margin-bottom: 7%;
}
.container-message span {
    display: block;
    color: #7e8382;
}
.headin-message {
    font-size: 35px;
    font-family: 'Roboto-Bold';
    line-height: 50px;
}
.content-message {
    margin-top: 40px;
    font-size: 30px;
    font-family: 'Roboto-Medium';
    line-height: 35px;
}
.accordion {
    max-width: 50%;
    margin: 0px auto;
}
.icon-open {
    width: 20px;
}
.question-tittle {
    font-size: 14pt;
    color: #7e8382;
    font-family: 'Roboto-Medium';
}
.answer-question {
    font-size: 14pt;
    color: #7e8382;
    font-family: 'Roboto-Light';
    line-height: 22px;
}
.footer-message {
    font-size: 20pt;
    color: #7e8382;
    font-family: 'Roboto-Medium';
}
.img-confirm {
    width: 3%;
}
.footer {
    height: auto;
    min-height: 60px;
    display: flex;
    background-color: #00325b;
}
.text-footer-page {
    text-align: center;
    margin: auto;
    color: white;
    font-size: 10px;
}
